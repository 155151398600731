// 角丸の有無 default: true
$enable-rounded: false;

// ユーザー設定によるCSSアニメーション無効化の有無 default: true
$enable-prefers-reduced-motion-media-query: true;

// Reponsive font sizeの有無 default: false
$enable-responsive-font-sizes: true;

// 標準の本文のfont-family default: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
$font-family-base: "Noto Sans JP",
"Helvetica Neue",
Arial,
"Hiragino Kaku Gothic ProN",
"Hiragino Sans",
Meiryo,
sans-serif;

// 標準のfont-size default: 1rem
$font-size-base: 1rem;

// h1~h6の標準のline-height default: 1.2
$headings-line-height: 1.3;

// 標準のline-height default 1.5
$line-height-base: 1.7;

// スペーサーのサイズ default: 1rem
$spacer: 1rem;

// bodyの背景 default: #fff
$body-bg: #fff;

// 本文の文字色 default: #111
$body-color: #111;

// グリッドのカラムの数 default: 12
$grid-columns: 12;

// ガッターのサイズ default: 30px
$grid-gutter-width: 30px;

// パンくずリストのセパレーター
$breadcrumb-divider: quote(">");