@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

/*--------------------------------------------------------------
# Object fit
--------------------------------------------------------------*/
img.object-fit-img-cover {
  object-fit: contain;
  font-family: "object-fit: cover;";
}

img.object-fit-img-contain {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

/**
 *  Font-family
 */
@font-face {
  font-family: "corporatelogo";
  src: url("../font/corporatelogo.woff2") format("woff2"),
       url("../font/corporatelogo.woff") format("woff");
}

$utilities: map-merge(
  $utilities,
  (
    "font-family": (
      property: font-family,
      class: font,
      responsive: false,
      values: $font-families,
    ),
  )
);

.font-league-gothic {
  font-weight: 400;
  font-style: normal;
}

/**
 *  Font-size
 */

$utilities: map-merge(
  $utilities,
  (
    "font-size":
      map-merge(
        map-get($utilities, "font-size"),
        (
          responsive: true,
        )
      ),
  )
);

/**
 *  Line-height
 */

$utilities: map-merge(
  $utilities,
  (
    "line-height":
      map-merge(
        map-get($utilities, "line-height"),
        (
          values:
            map-merge(
              $line-heights,
              (
                1: 1,
                sm: $line-height-sm,
                base: $line-height-base,
                lg: $line-height-lg,
              )
            ),
        )
      ),
  )
);

/**
 *  Letter-spacing
 */

$utilities: map-merge(
  $utilities,
  (
    "letter-spacing": (
      property: letter-spacing,
      class: ltsp,
      responsive: false,
      values: $letter-spacings,
    ),
  )
);

/*--------------------------------------------------------------
# Border
--------------------------------------------------------------*/
/**
 *  Border-radius
 */

$utilities: map-merge(
  $utilities,
  (
    "rounded":
      map-merge(
        map-get($utilities, "rounded"),
        (
          values:
            map-merge(
              $border-radiues-sizes,
              (
                null: $border-radius,
                0: 0,
                1: $border-radius-sm,
                2: $border-radius,
                3: $border-radius-lg,
                circle: 50%,
                pill: $border-radius-pill,
              )
            ),
        )
      ),
  )
);

/**
 *  Border-width
 */

$border-width-sizes: (
  "2": 0.125,
  "3": 0.1875,
  "4": 0.25,
);

@each $key, $value in $border-width-sizes {
  .bdrs-#{$key} {
    border-radius: #{$value}rem !important;
  }
}

/*--------------------------------------------------------------
# Background
--------------------------------------------------------------*/
.bg {
}

/*--------------------------------------------------------------
# Hover
--------------------------------------------------------------*/
.hover-transition {
  transition: 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
}

/*--------------------------------------------------------------
# Transition
--------------------------------------------------------------*/
.transition {
  transition: ease-out 0.3s;
}
