/*--------------------------------------------------------------
# Front page
--------------------------------------------------------------*/
@mixin sp-only {
  @media (max-width: 767px) {
    @content;
  }
}

.front-page {
  .hero {
    .video-bg {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      .video {
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
        height: 100vh; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
        min-width: 100%;
        min-height: 100%;
      }
    }
    .hero-fireplace {
      height: 130vw;
      width: 15%;
      min-width: 170px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(75deg);
      .video {
        width: 700%;
        height: auto;
        bottom: -40%;
        @include media-breakpoint-up(lg) {
          bottom: -10%;
        }
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
    .hero-text {
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 70%;
      height: auto;
    }
  }
  .scroll-nav {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: 0.5rem;
    transition: .3s ease-out;
    &.hidden {
      opacity: 0;
    }
    @include media-breakpoint-up(lg) {
      right: 1rem;
    }
    .scroll-nav-item {
      width: 0.5rem;
      height: 0.5rem;
      background-color: $gray-3;
      &.is-active {
        background-color: $gray-a;
      }
      @include media-breakpoint-up(lg) {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .lead {
    .bubble-shirankedo {
      width: 120px;
      bottom: 0;
      right: -50px;
      @include media-breakpoint-up(xl) {
        right: 0;
      }
    }
  }
}

.section#content-1 {
  background-image: url("../imgs/base_sozai.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.section#content-2,
.section#content-4 {
  position: relative;
  
  .white-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    z-index: -1;
    background-image: url('../imgs/base.png');
    background-repeat: repeat;
  }
}

.section#content-3,
.section#content-5 {
  background-image: url("../imgs/base.png");
  background-repeat: repeat;
}

.section#content-4 {
  position: relative;

  h3 {
    color: black;
    font-size: 5.8rem;

    @include sp-only() {
      font-size: 6vw;
    }
  }

  .container {
    height: 738px;

    @include sp-only() {
      height: 300px;
      padding: 0 20px !important;
    }
  }

  .device-image {
    height: 738px;

    @include sp-only() {
      height: 75vw;
      width: 100%;
      object-fit: cover;
    }
  }

  .device-desc {
    position: absolute;
    left: calc(50% - 470px);
    top: 22%;
    width: 380px;

    &.desc2 {
      top: 46.5%;
    }

    &.desc3 {
      top: 55%;
    }

    &.desc4 {
      top: 63%;
    }

    &.desc5 {
      top: 75.5%;
    }

    &.desc6 {
      left: calc(50% + 155px);
      top: 15%;
      width: 320px;
    }

    &.desc7 {
      left: calc(50% + 155px);
      top: 26%;
      width: 320px;
    }

    &.desc8 {
      left: calc(50% + 155px);
      top: 37%;
      width: 320px;
    }

    &.desc9 {
      left: calc(50% + 155px);
      top: 53.5%;
      width: 320px;
    }

    @include sp-only() {
      left: 7vw;
      top: 26.5%;
      word-break: break-all;
      width: 31vw;

      &.desc2 {
        top: 47.5%;
      }

      &.desc3 {
        top: 56%;
      }

      &.desc4 {
        top: 68%;
      }

      &.desc5 {
        top: 80%;
      }

      &.desc6 {
        left: 66vw;
        top: 20%;
        width: 31vw;
      }

      &.desc7 {
        left: 66vw;
        top: 30%;
        width: 31vw;
      }

      &.desc8 {
        left: 66vw;
        top: 41%;
        width: 31vw;
      }

      &.desc9 {
        left: 66vw;
        top: 56%;
        width: 31vw;
      }
    }
  }

  .desc-title {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;

    @include sp-only() {
      font-size: 2.8vw;
      line-height: 3.5vw;
    }
  }

  .desc-content {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;

    @include sp-only() {
      font-size: 2.5vw;
      line-height: 3.2vw;
    }
  }
}

.section#content-3 {
  h3 {
    color: white;
    font-size: 5.8rem;

    @include sp-only() {
      font-size: 6vw;
    }
  }

  .container {
    min-height: 85vh;

    @include sp-only() {
      padding: 40px !important;
    }
  }

  .photo-item {
    padding: 0 .5rem; 

    .photo-img {
      position: relative;
      padding-top: 67%;
      width: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .photo-text {
      background-color: #000;
      color: #fff;
      font-size: 1rem;
      line-height: 3rem;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 1rem;
    }

    @include sp-only() {
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        margin-bottom: 1rem;
      }
    }
  }
}

.section#content-2 {
  h4 {
    width: 100%;
    text-align: center;
    padding-top: 80px;
    margin-bottom: 50px;
    font-size: 1.5rem;
    letter-spacing: 8px;

    @include sp-only() {
      font-size: 3.5vw;
    }
  }

  h5 {
    text-align: center;
    width: 100%;
    margin-top: 50px;
    font-size: 2.5rem;

    @include sp-only() {
      font-size: 4.5vw;
    }
  }

  h3 {
    text-align: center;
    width: 100%;
    font-size: 4rem;

    @include sp-only() {
      font-size: 6vw;
    }
  }

  .devices {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14%;
      max-height: 450px;
      object-fit: cover;
      height: 30vw;
    }

    @include sp-only() {
      min-height: 50vh;
    }
  }
}

.section#content-5 {
  h3 {
    color: white;
    font-size: 5.8rem;

    @include sp-only() {
      font-size: 6vw;
    }
  }

  .img-letter {
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    opacity: .2;

    @include sp-only() {
      width: calc(100% - 64px);
    }
  }

  .container {
    min-height: 76vh;

    .desc-content {
      padding: 110px 260px 0;

      .description {
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin-bottom: 3.75rem;
        font-weight: bold;
      }

      .images {
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
          height: 200px;
          object-fit: contain;
          border-radius: 16px;
          overflow: hidden;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .machine {
        position: absolute;
        left: 70px;
        bottom: -24px;
        width: 180px;
        object-fit: contain;
      }

      @include sp-only() {
        padding: 0;
        text-align: center;

        .machine {
          position: relative;
          bottom: auto;
          margin: 20vw auto 6vw;
          left: auto;
        }

        .description {
          margin-bottom: 0;
          font-size: 6vw;
        }
      }
    }

    @include sp-only() {
      padding: 32px;
    }
  }

  .footer {
    width: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    z-index: 2;
    padding: 16px 0;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
}

.front-page .scroll-nav {
  z-index: 1000;
}

.pc {
  @include sp-only() {
    display: none;
  }
}

.sp {
  display: none;

  @include sp-only() {
    display: block;
  }
}

.sp-px-2 {
  @include sp-only() {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }
}

.modal-content {
  background-image: url("../imgs/modal_bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.modal-header .btn-close {
  background-image: none;
  border-radius: 50%;
  background-color: #aaa;
  position: relative;
  opacity: 1;

  &:after {
    content: "X";
    position: absolute;
    top: 6px;
    left: 9px;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
  }

  &:hover {
    opacity: .8;
  }
}

// # Carousel
.slider {
  width: 100%;
  height: 350px;
  position: relative;
  overflow: hidden;

  .slide {
    width: 100%;
    max-width: 800px;
    height: 350px;
    position: absolute;
    transition: all 0.5s;
  }

  .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &.hidden {
      display: none;
    }
  }

  .btn:active {
    transform: scale(1.1);
  }

  .btn-prev {
    top: 45%;
    left: 2%;
    background-image: url('../imgs/arrow_left.png');
  }

  .btn-next {
    top: 45%;
    right: 2%;
    background-image: url('../imgs/arrow_right.png');
  }
}

.slider-box {
  display: flex;
  align-items: flex-end;

  .slider {
    flex: 1;
  }

  .device-selects {
    width: 20%;
    margin-left: 40px;

    h4 {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: bold;
    }

    .color-items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .color-item {
      width: 46%;
      border: 2px solid #fff;
      border-radius: 8px;
      height: 60px;
      cursor: pointer;

      &:nth-child(odd) {
        margin-right: 16px;
      }

      &.black {
        background-color: #000;
        border-color: #000;
      }

      &.white {
        background-color: #fff;
        border-color: #000;
      }

      &.red {
        background-color: #cb1a22;
        border-color: #cb1a22;
      }

      &.brown {
        background-color: #493400;
        border-color: #493400;
      }

      &.blue {
        background-color: #0000a0;
        border-color: #0000a0;
      }

      &.green {
        background-color: #007000;
        border-color: #007000;
      }

      &.active {
        border-color: #ea00ff;
      }
    }
  }

  @include sp-only() {
    flex-wrap: wrap;

    .slider {
      flex: 0 1 100%;
    }

    .device-selects {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

@include sp-only() {
  .section#content-4 {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

/*--------------------------------------------------------------
# Sub page
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Archive
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Single
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Search
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# 404
--------------------------------------------------------------*/
