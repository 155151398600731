/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.site-header {
  .header-brand {
    @include z-index(header-brand);
    .header-brand-logo {
      width: 70px;
      @include media-breakpoint-up(lg) {
        width: 130px;
      }
    }
    .header-brand-bg {
      width: 200px;
      @include media-breakpoint-up(lg) {
        width: 400px;
      }
    }
  }
  .header-btns {
    @include z-index(header-btns);
  }
  .lang-switch {
    .lang-switch-item {
      color: $white;
      &.is-active {
        color: $blue;
        pointer-events: none;
      }
    }
  }
}

/*--------------------------------------------------------------
# Site navigation mobile
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Main visual
--------------------------------------------------------------*/
