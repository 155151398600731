/*--------------------------------------------------------------
# Global utility
--------------------------------------------------------------*/
html {
  // scroll-behavior: smooth;
}

body {
  // letter-spacing: 0.1em;
}

a {
  @extend .transition;

  text-decoration: none;
}
.content {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.section {
  scroll-snap-align: start;
}
