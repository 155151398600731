/*--------------------------------------------------------------
# Component
--------------------------------------------------------------*/
.c-form-divide {
  height: 1px;
  width: 100%;
  background-color: $orange;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid $orange;
    top: -4px;
    right: calc(100% - 1px);
  }
}

.c-entry-input {
  @extend .form-control;
  border-width: 0 0 1px;
  border-color: black !important;
  &:focus {
    border-width: 1px;
    background-color: #efefef;
    box-shadow: none;
  }
  &-lg {
    height: 80px;
  }
}

.c-entry-input-range {
  @extend .form-range;
  @include media-breakpoint-up(lg) {
    max-width: 200px;
  }
  border-radius: 9999px !important;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: $orange;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  /* Moz向けのつまみ */
  &::-moz-range-thumb {
    background: $orange;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
  }
}

.c-slider-item {
  display: block;
  border-bottom: 1px dotted $gray-b;
  padding: 0.5rem 0;
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-entry-input-file {
  max-width: 240px;
  background-color: $orange-light;
  aspect-ratio: 1/1;
  display: block;
  transform: 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
  input[type="file"] {
    display: none;
  }
}
