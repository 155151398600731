/*--------------------------------------------------------------
# Default color
--------------------------------------------------------------*/
$primary: #ed7700;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #19191c;
$transparent: transparent;

$white: #fff;
$black: #000;

$red: #c61f0e;
$orange: #ed7700;
$orange-light: #fef1e6;

$gray-1: #111;
$gray-3: #333;
$gray-a: #aaa;
$gray-b: #bbb;

$blue: #1b91ca;
$blue-2: #0b2e6f;

/*--------------------------------------------------------------
# Color
--------------------------------------------------------------*/
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white,
  "black": $black,
  "red": $red,
  "orange": $orange,
  "orange-light": $orange-light,
  "gray-1": $gray-1,
  "gray-3": $gray-3,
  "gray-a": $gray-a,
  "blue": $blue,
  "blue-2": $blue-2
);

/*--------------------------------------------------------------
# Spacer
--------------------------------------------------------------*/
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  e32: $spacer * 2,
  e80: $spacer * 5,
  e100: $spacer * 6.25,
);

/*--------------------------------------------------------------
# Font family
--------------------------------------------------------------*/
$font-families: (
  league-gothic: "league-gothic",
  corporatelogo: "corporatelogo",
  noto-sans: '"Noto Sans JP", sans-serif',
);

/*--------------------------------------------------------------
# Font size
--------------------------------------------------------------*/
/**
 *  Heading
 */
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

/**
 *  Display
 */
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  e18: $font-size-base * 1.125,
  e20: $font-size-base * 1.25,
  e24: $font-size-base * 1.5,
  e32: $font-size-base * 2,
  e36: $font-size-base * 2.25,
  e48: $font-size-base * 3,
  e70: $font-size-base * 4.375,
  e100: $font-size-base * 6.25,
);

/*--------------------------------------------------------------
# Line height
--------------------------------------------------------------*/
$line-heights: (
  "10": 1,
  "13": 1.3,
  "15": 1.5,
);

/*--------------------------------------------------------------
# Letter spacing
--------------------------------------------------------------*/
$letter-spacings: (
  "0": 0,
  "10": "0.1em",
);

/*--------------------------------------------------------------
# Border radius
--------------------------------------------------------------*/
$border-radiues-sizes: (
  "20": 1.25,
);

/*--------------------------------------------------------------
# Border width
--------------------------------------------------------------*/
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);
